import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import MarkdownView from 'react-showdown';
import {Row, Col} from 'reactstrap';
import Layout from "../components/Layout"
import SEO from "../components/seo";
import Slider from "../components/Slider";

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query AboutUsQuery {
      allStrapiAboutUs {
        nodes {
          Body
          Title
          Image {
            alternativeText
            caption
            url
          }
        }
      }
    }
  `);

  const {Body, Title, Image} = data.allStrapiAboutUs.nodes[0];

  const imageArray = Image.map(img => {
    img.altText = img.alternativeText;
    img.src = img.url;
    return img;
  });

  return (
    <Layout>
      <SEO
        title="Choice Automotive Equipment - Tennesse's premier distributor of automotive shop equipment and service, parts department equipment and service, and industrial lighting equipment and service."
        description="Choice Automotive Equipment is Tennessee's premier distributor of automotive shop equipment and service, parts department equipment and service, and industrial lighting equipment and service."
      />
      <div className="bg-light p-4">
        <h1 className="text-center">{Title}</h1>
        <Row>
          <Col md={6} className="order-md-2">
            <Slider items={imageArray}/>
          </Col>
          <Col md={6} className="order-md-1">
            <MarkdownView 
              markdown={Body}
            />
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default IndexPage;